import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import Layout from 'components/Layout'
import SEO from 'components/Seo'
import { IntlProvider } from 'contexts/intl/IntlContext'
import RepoCard from '../../components/RepoCard'

const Index = ({ location }) => (
  <IntlProvider pathname={location.pathname}>
    {({ locale, formatMessage, getPath }) => (
      <Layout>
        <SEO
          lang={locale}
          title={formatMessage({ id: 'tools.title' })}
          description={formatMessage({ id: 'meta.description' })}
          keywords={formatMessage({ id: 'meta.keywords' })}
          url={formatMessage({ id: 'meta.url.tools' })}
          isBlogPost={true}
          ogTitle={formatMessage({ id: 'tools.title' })}
          ogUrl={formatMessage({ id: 'meta.url.tools' })}
          ogDescription={formatMessage({ id: 'meta.description' })}
          ogKeywords={formatMessage({ id: 'meta.keywords' })}
          ogLocale={locale}
          twitterTitle={formatMessage({ id: 'tools.title' })}
          twitterDescription={formatMessage({ id: 'meta.description' })}
          twitterCreator={'Mihir Pipermitwala'}
          siteName={'Mihir Pipermitwala'}
        />
        <section className='section is-size-4-desktop is-size-5-touch'>
          <h1 className='title has-text-centered has-text-light'>
            <FormattedMessage id='tools.title' />
          </h1>
          <div className='container'>
            <hr />
            <div className='columns'>
              <div className='column is-half is-offset-one-quarter'>
                <RepoCard
                  title='QR Code Generator'
                  subtitle='QR Code Generator and download in high print quality. Get your free QR Codes now!'
                  link='qr-code-generator'
                />

                <RepoCard
                  title='Analog Clock'
                  subtitle='It is a circular faced clock with the numbers one to twelve and three hands, shorter one to measure hours , longer one to measure minutes and red one for seconds.'
                  link='analog-clock'
                />
              </div>
            </div>
            <p className='has-text-centered'>
              <FormattedMessage id='checkOut' />{' '}
              <Link to={getPath('topics', locale)}>
                <FormattedMessage id='topics.title' />
              </Link>
              {'.'}
            </p>
          </div>
        </section>
      </Layout>
    )}
  </IntlProvider>
)

Index.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default Index
